import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { DateRange } from "react-day-picker";
import React from "react";
import { DatePickerWithRange } from "./DateRangePicker";
import { format, toZonedTime } from "date-fns-tz";

function formatDateUTC(date: Date): string {
  const utcDate = toZonedTime(date, "UTC");
  return format(utcDate, "yyyy-MM-dd", { timeZone: "UTC" });
}
const QueryDateRangePicker: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const since = searchParams.get("since") || undefined;
  const until = searchParams.get("until") || undefined;

  // If the user has not selected a date range, we default to the last 6 months and the next 3 days.
  const [date, setDate] = React.useState<DateRange | undefined>({
    // We explicitly set the default date range to ensure the dates exist when
    // searchParams are pushed.
    from: new Date(
      since || new Date().getTime() - 6 * 30 * 24 * 60 * 60 * 1000,
    ),
    to: new Date(until || new Date().getTime() + 3 * 24 * 60 * 60 * 1000),
  });

  useEffect(() => {
    const since = date?.from;
    const until = date?.to ?? since;

    setSearchParams((prevParams) => {
      const newParams = new URLSearchParams(prevParams);

      if (since === undefined || until === undefined) {
        newParams.delete("since");
        newParams.delete("until");
      } else {
        newParams.set("since", formatDateUTC(since));
        newParams.set("until", formatDateUTC(until));
      }

      return newParams;
    });
  }, [date, setSearchParams]);

  return <DatePickerWithRange className="" date={date} setDate={setDate} />;
};

export default QueryDateRangePicker;
