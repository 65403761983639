import { useOrganization } from "@clerk/clerk-react";
import { Link, useParams, useSearchParams } from "react-router-dom";
import Page from "./Page";
import { useEffect } from "react";
import { Feed } from "../types";
import QueryDateRangePicker from "./QueryDateRangePicker";
import { Card, CardDescription, CardHeader, CardTitle } from "./ui/card";

import { useFeatureFlags, useFeeds } from "@/hooks";

import Spinner from "./Loading";
import { formatDistanceToNow } from "date-fns";
import FilteredByFixtures from "./FilteredByFixture";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "./ui/dropdown-menu";
import { EllipsisVerticalIcon } from "@heroicons/react/24/outline";
import { Badge } from "./ui/badge";

interface PublicMetadata {
  default_search_params?: {
    [key: string]: [string, string][];
  };
}

interface ExtendedOrganization {
  publicMetadata: PublicMetadata;
}

const flagTranslations: Record<string, string> = {
  german: "🇩🇪",
  english: "🇬🇧",
  spanish: "🇪🇸",
  french: "🇫🇷",
  italian: "🇮🇹",
  dutch: "🇳🇱",
  portuguese: "🇵🇹",
  turkish: "🇹🇷",
  polish: "🇵🇱",
  japanese: "🇯🇵",
  chinese: "🇨🇳",
  korean: "🇰🇷",
};

export default function FeedsPage() {
  const { orgId } = useParams();
  const { organization } = useOrganization();
  const [searchParams, setSearchParams] = useSearchParams();
  useEffect(() => {
    if (organization) {
      const extendedOrg = organization as ExtendedOrganization;
      const defaultParams =
        extendedOrg.publicMetadata.default_search_params?.["/feeds"];
      if (defaultParams) {
        const newParams = new URLSearchParams(searchParams);
        defaultParams.forEach(([key, value]) => {
          if (!newParams.has(key)) {
            newParams.set(key, value);
          }
        });
        setSearchParams(newParams);
      }
    }
  }, [organization, searchParams, setSearchParams]);

  const { feeds, isLoading, error } = useFeeds(orgId!, searchParams);
  if (error) {
    return (
      <Page>
        <div>Error: {error.message}</div>
      </Page>
    );
  }
  return (
    <Page>
      <header>
        <h1 className="text-3xl font-bold leading-tight tracking-tight text-gray-900">
          Feeds
        </h1>
      </header>
      <main className="mt-4">
        <div className="flex flex-shrink flex-col justify-between space-y-2 md:flex-row md:space-x-2 md:space-y-0">
          <FilteredByFixtures size={"sm"} />
          <QueryDateRangePicker />
        </div>

        <div className="mt-4">
          {isLoading ? (
            <Spinner />
          ) : (
            <FeedList
              feeds={feeds.sort(
                (a, b) =>
                  new Date(b.created_at).getTime() -
                  new Date(a.created_at).getTime(),
              )}
            />
          )}
        </div>
      </main>
    </Page>
  );
}

export function FeedList({ feeds }: { feeds: Feed[] }) {
  return (
    <ul className="space-y-4">
      {feeds.map((feed, i) => (
        <FeedCard key={i} {...feed} />
      ))}
    </ul>
  );
}

const capitalize = (str: string) =>
  str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();

function FeedCard(feed: Feed) {
  const { orgId } = useParams();
  const { isFeatureEnabled } = useFeatureFlags();

  return (
    <li>
      <Link to={`/${orgId}/feeds/${feed.id}`}>
        <Card>
          <CardHeader>
            <CardTitle className="flex w-full items-center justify-between">
              <div className="flex items-center gap-2">
                {feed.title}
                <Badge className="bg-gray-200 text-gray-800">
                  In {capitalize(feed.language)}{" "}
                  {flagTranslations[feed.language] || feed.language}
                </Badge>
              </div>

              {isFeatureEnabled("writers") && (
                <DropdownMenu>
                  <DropdownMenuTrigger>
                    <EllipsisVerticalIcon className="h-7 w-7 "></EllipsisVerticalIcon>
                  </DropdownMenuTrigger>
                  <DropdownMenuContent>
                    <DropdownMenuItem
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                    >
                      <Link to={`/${orgId}/writers?feed_id=${feed.id}`}>
                        View Writer
                      </Link>
                    </DropdownMenuItem>
                  </DropdownMenuContent>
                </DropdownMenu>
              )}
            </CardTitle>
            <CardDescription>
              {isFeatureEnabled("writers") && (
                <div className="text-xs text-gray-500">ID: {feed.id}</div>
              )}{" "}
              Created{" "}
              {formatDistanceToNow(feed.created_at, { addSuffix: true })} -
              Updated{" "}
              {formatDistanceToNow(feed.updated_at, { addSuffix: true })}
            </CardDescription>
          </CardHeader>
        </Card>
      </Link>
    </li>
  );
}
